<template>
  <v-card class="mt-10 mb-10 custom-center">
    <v-card-text
        v-if="vendor_image || vendor_name || vendor_orn || vendor_emails.length > 0 || vendor_phones.length > 0 || vendor_websites.length > 0 || vendor_addresses.length > 0">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" class="pb-0 pt-0">
          <img style="width: 40%" :src="vendor_image" alt="Logo" title="Logo">
        </v-col>
        <v-col cols="12" sm="6" class="pb-0 pt-0">
          <div class="float-right">
            <h2 style="color: #000000" v-if="vendor_name">{{ vendor_name }}</h2>
            <p v-if="vendor_orn"><strong>ORN:
              {{ vendor_orn }}
            </strong></p>
            <p class="custom-item-comma" v-if="vendor_emails.length > 0"><strong>Email(s):
              <a :href="'mailto:' + email" v-for="(email, eind) in vendor_emails" :key="eind">
                {{ email }}<span class="comma">,</span>
              </a>
            </strong></p>
            <p class="custom-item-comma" v-if="vendor_phones.length > 0"><strong>Phone(s):
              <a :href="'tel:' + phone" v-for="(phone, pind) in vendor_phones" :key="pind">
                {{ phone }}<span class="comma">,</span>
              </a>
            </strong></p>
            <p class="custom-item-comma" v-if="vendor_websites.length > 0"><strong>Website(s):
              <a :href="'//'+web" target="_blank" v-for="(web, wind) in vendor_websites" :key="wind">
                {{ web }}<span class="comma">,</span>
              </a>
            </strong></p>
            <p class="custom-item-comma" v-if="vendor_addresses.length > 0"><strong>Address(es):
              <a v-for="(add, aind) in vendor_addresses" :key="aind">
                {{ add }}<span class="comma">,</span>
              </a>
            </strong></p>
          </div>
        </v-col>
        <!--      <v-card flat>-->
        <!--        <v-card-text>-->
        <!--          <div v-if="vendor_image">-->
        <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
        <!--          </div>-->
        <!--          <div v-if="vendor_emails" class="float-right">-->
        <!--            <img style="width: 20%" :src="vendor_image" alt="Logo" title="Logo">-->
        <!--          </div>-->
        <!--        </v-card-text>-->
        <!--      </v-card>-->
        <!--      <v-col cols="12" sm="12">-->
        <!--        <a href="https://www.afsrealestate.com" target="_blank">-->
        <!--          <img style="width: 100%" src="media/company/header.jpg" alt="Logo" title="AFS Real Estate">-->
        <!--        </a>-->
        <!--      </v-col>-->
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text v-if="editedItem.max_sale_price || editedItem.max_rent_per_annum">
      <v-row>
        <v-col cols="12" sm="12">
          <h4 v-html="editedItem.hk"></h4>
        </v-col>
      </v-row>
      <v-row v-if="editedItem.unit_no">
        <v-col cols="12" sm="6">
          <h4>Unit Number</h4>
        </v-col>
        <v-col cols="12" sm="6">
          <h4 class="text-right">{{ editedItem.unit_no }}</h4>
        </v-col>
      </v-row>
      <v-row v-if="editedItem.purpose">
        <v-col cols="12" sm="6">
          <h4>Purpose</h4>
        </v-col>
        <v-col cols="12" sm="6">
          <h4 class="text-right">{{ editedItem.purpose }}</h4>
        </v-col>
      </v-row>
      <div v-if="editedItem.purpose === 'Rent'">
        <v-row>
          <v-col cols="12" sm="6">
            <h4>Rent Per Annum</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.max_rent_per_annum) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.parent_property_type && editedItem.parent_property_type === 'Commercial'">
          <v-col cols="12" sm="6">
            <h4>VAT (5%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ getPercentage("5", editedItem.max_rent_per_annum) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.rent_agency_fee_tenant">
          <v-col cols="12" sm="6">
            <h4>Agency Fee Tenant ({{ editedItem.rent_agency_fee_tenant_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.rent_agency_fee_tenant) }} + 5% VAT
              {{ getPercentage("5", editedItem.rent_agency_fee_tenant) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.rent_agency_fee_landlord">
          <v-col cols="12" sm="6">
            <h4>Agency Fee Landlord ({{ editedItem.rent_agency_fee_landlord_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.rent_agency_fee_landlord) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.rent_security_fee">
          <v-col cols="12" sm="6">
            <h4>Security Deposit ({{ editedItem.rent_security_fee_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.rent_security_fee) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.rent_frequency">
          <v-col cols="12" sm="6">
            <h4>Rent Frequency</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ editedItem.rent_frequency }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.total_payable && editedItem.total_payable > 0">
          <v-col cols="12" sm="6">
            <h4>Total</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.total_payable) }}</h4>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" sm="6">
            <h4>Sale Price</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.max_sale_price) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.parent_property_type && editedItem.parent_property_type === 'Commercial'">
          <v-col cols="12" sm="6">
            <h4>VAT (5%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ getPercentage("5", editedItem.max_sale_price) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_original_price">
          <v-col cols="12" sm="6">
            <h4>Original Price</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_original_price) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_paid_of_original_price">
          <v-col cols="12" sm="6">
            <h4>POP ({{ editedItem.paid_of_original_price_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_paid_of_original_price) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_discount">
          <v-col cols="12" sm="6">
            <h4>Discount</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_discount) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_premium">
          <v-col cols="12" sm="6">
            <h4>Premium</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_premium) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_agency_fee_buyer">
          <v-col cols="12" sm="6">
            <h4>Agency Fee Buyer ({{ editedItem.sale_agency_fee_buyer_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_agency_fee_buyer) }} + 5% VAT
              {{ getPercentage("5", editedItem.sale_agency_fee_buyer) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_agency_fee_seller">
          <v-col cols="12" sm="6">
            <h4>Agency Fee Seller ({{ editedItem.sale_agency_fee_seller_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_agency_fee_seller) }} + 5% VAT
              {{ getPercentage("5", editedItem.sale_agency_fee_seller) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_transfer_fee_buyer">
          <v-col cols="12" sm="6">
            <h4>Transfer Fee Buyer ({{ editedItem.sale_transfer_fee_buyer_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_transfer_fee_buyer) }}
            </h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_transfer_fee_seller">
          <v-col cols="12" sm="6">
            <h4>Transfer Fee Seller ({{ editedItem.sale_transfer_fee_seller_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_transfer_fee_seller) }}
            </h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_trustee_office_fee">
          <v-col cols="12" sm="6">
            <h4>Trustee Office Fee</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_trustee_office_fee) }} + 5% VAT
              {{ getPercentage("5", editedItem.sale_trustee_office_fee) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_dev_noc_fee">
          <v-col cols="12" sm="6">
            <h4>Developer NOC Fee</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_dev_noc_fee) }} + 5% VAT
              {{ getPercentage("5", editedItem.sale_dev_noc_fee) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_legal_fee">
          <v-col cols="12" sm="6">
            <h4>Legal Fee ({{ editedItem.sale_legal_fee_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_legal_fee) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_dld_fee">
          <v-col cols="12" sm="6">
            <h4>Dubai Land Department Fee ({{ editedItem.sale_dld_fee_percentage }}%)</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_dld_fee) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_payable_now">
          <v-col cols="12" sm="6">
            <h4>Payable To Seller</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_payable_now) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.sale_payable_now_developer">
          <v-col cols="12" sm="6">
            <h4>Payable To Developer</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.sale_payable_now_developer) }}</h4>
          </v-col>
        </v-row>
        <v-row v-if="editedItem.total_payable && editedItem.total_payable > 0">
          <v-col cols="12" sm="6">
            <h4>Total</h4>
          </v-col>
          <v-col cols="12" sm="6">
            <h4 class="text-right">{{ addTSBV(editedItem.total_payable) }}</h4>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-row v-if="editedItem.hasOwnProperty('listing_fees') && editedItem.listing_fees.length > 0"
             v-for="(dt, i) in editedItem.listing_fees" :key="i">
        <v-col cols="12" sm="6">
          <h4 v-html="dt.title"></h4>
        </v-col>
        <v-col cols="12" sm="6">
          <h4 class="text-right">
            <span>({{ addTSBV(dt.amount) }})</span>
            <span v-if="dt.paid_by"> Paid By: {{ dt.paid_by }}, </span>
            <span v-if="dt.paid_to"> Paid To: {{ dt.paid_to }}</span>
          </h4>
        </v-col>
      </v-row>
      <v-row v-if="editedItem.hasOwnProperty('listing_fees') && editedItem.listing_fees.length > 0">
        <v-col cols="12" sm="6">
          <h4>Other Fee Total</h4>
        </v-col>
        <v-col cols="12" sm="6">
          <h4 class="text-right">{{ addTSBV(editedItem.total_payable_other_fee) }}</h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {REPORT_LISTING_PRICE} from "@/core/services/store/reports.module";

export default {
  name: "listing-price-reports",

  mounted() {
    if (this.$route.query.hasOwnProperty('ref') && this.$route.query.hasOwnProperty('tkn') && this.$route.query.hasOwnProperty('okn')) {
      this.getListingDetails(this.$route.query.ref, this.$route.query.tkn, this.$route.query.okn)
    }
  },
  data() {
    return {
      editedItem: {},
      vendor_image: '',
      vendor_name: '',
      vendor_orn: '',
      vendor_emails: [],
      vendor_phones: [],
      vendor_websites: [],
      vendor_addresses: [],
    }
  },
  methods: {
    getListingDetails(ref = '', tkn = '', okn = '') {
      if (ref && tkn && okn) {
        this.$store.dispatch(REPORT_LISTING_PRICE, {
          ref: ref,
          okn: okn,
          tkn: tkn
        }).then((res) => {
          this.editedItem = res.records;
          if (res.records.vendor_image) this.vendor_image = res.records.vendor_image
          if (res.records.vendor_name) this.vendor_name = res.records.vendor_name
          if (res.records.vendor_orn) this.vendor_orn = res.records.vendor_orn
          if (res.records.vendor_emails.length > 0) this.vendor_emails = res.records.vendor_emails
          if (res.records.vendor_phones.length > 0) this.vendor_phones = res.records.vendor_phones
          if (res.records.vendor_websites.length > 0) this.vendor_websites = res.records.vendor_websites
          if (res.records.vendor_addresses.length > 0) this.vendor_addresses = res.records.vendor_addresses
        });
      }
    },
  },
  computed: {}
};
</script>
